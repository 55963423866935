/* App.css */

body {
  background-color: #42025a;
}

html,
body {
  height: 100%;
  min-height: 100%;
  background-color: #42025a;
  margin: 0;
  padding: 0;
}


.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.confirmation h2 {
  font-size: 24px;
  color: white;
  margin-bottom: 16px;
}

.confirmation p {
  font-size: 16px;
  color: white;
  margin-bottom: 16px;
}

.confirmation-button {
  padding: 10px 20px;
  background-color: #bf0909;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirmation-button:hover {
  background-color: #bf0909;
}