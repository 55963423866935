/* NewsComponent.css */

.news-container {
    width: auto;
    max-width: 1150px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 0px;
}

.news-item {
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

h2 {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    color: white;
    line-height: 1.6;
}